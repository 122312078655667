/**
 * Pages
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

}(jQuery));