window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
    '--s': '(max-width: 479px)',
    '--m': '(max-width: 767px)',
    '--l': '(max-width: 1023px)',
    '--xl': '(max-width: 1279px)'
};

var allLazyImages = document.querySelectorAll('.lazyload');
Array.prototype.forEach.call(allLazyImages, function(img){
    img.addEventListener('load', function () {
        $(img).closest('picture').addClass('picture--loaded');
    });
});