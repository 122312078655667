/**
 * CY
 *
 * 01. Tag Città
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
    "use strict";

    /**
     * Tag Città
     *
     * @requires plugins/list.js
     */
    var tags = $('.js-tag-location'),
        cyList = new List('js-cy', {
            valueNames: ['js-location']
        });

    tags.on('click', function (e) {
        e.preventDefault();

        var tag = $(this),
            loc = tag.data('location');

        if (loc) {
            cyList.filter(function (item) {
                return item.values()['js-location'] === loc;
            });
        } else {
            cyList.filter();
        }

        tags.removeClass('tags__item—-active');
        tag.addClass('tags__item—-active');
    });

}(jQuery));