/**
 * filters.js
 *
 * @author Daniele De Nobili
 * @requires plugins/nouislider.js
 */

/*jslint nomen: true, plusplus: true, passfail: true, browser: true, devel: true */
/*global jQuery */

(function ($) {
    "use strict";

    var cityField = $('.js-field-city'),
        zoneField = $('.js-field-zone'),
        priceRange = $('.js-pricerange');

    // esco se i campi non ci sono
    if (!cityField.length || !zoneField.length || !priceRange.length) {
        return;
    }

    // slider dei prezzi
    priceRange.each(function(index, filter) {
        var $filter = $(filter),
            $slider = $filter.find('.js-slider'),
            slider = $slider.get(0),
            labelValues = [
                $filter.find('.js-value-min'),
                $filter.find('.js-value-max')
            ],
            input = $filter.find('input');

        noUiSlider.create(slider, {
            start: input.val().split('-'),
            connect: true,
            margin: 10,
            step: 10,
            range: {
                'min': $slider.data('min') || 0,
                'max': $slider.data('max') || 100
            },
            tooltips: false,
            format: {
                to: function(value) {
                    return '€ ' + Math.round(value);
                },
                from: function(value) {
                    return Math.round(value.replace('€ ', ''));
                }
            }
        });

        slider.noUiSlider.on('update', function(values, handle) {
            var value = [];

            values.forEach(function(v) {
                value.push(v.replace('€ ', ''));
            });

            labelValues[handle].text(values[handle]);
            input.val(value.join('-'));
        });
    });

    // aggiorna le opzioni per la select delle zone
    function prepareZoneSelect(selectedZone) {
        var selectedCity = parseInt(cityField.val(), 10),
            options = '<option value="">' + zoneField.data('any-option') + '</option>';

        $.map(window.filterZones, function (zone) {
            if (parseInt(zone.city, 10) === selectedCity) {
                options += '<option value="' + zone.value + '"' + (zone.value === selectedZone ? ' selected' : '') + '>' + zone.name + '</option>';
            }

            zoneField.html(options);
        });
    }

    // al caricamento della pagina
    if (window.filterSelectedZone !== undefined) {
        prepareZoneSelect(window.filterSelectedZone);
    } else {
        prepareZoneSelect();
    }

    // all'aggiornamento della select delle città
    cityField.on('change', function () {
        prepareZoneSelect();
    });
}(jQuery));
