/**
 * RE
 *
 * 01. Infinite Scroll
 * 02. Carousel Sync
 * 03. Viewmode
 * 04. Filters - Price range
 *
 * @global jQuery
 * @author Matteo Casonato
 */


(function ($) {
	'use strict';

	/**
	 * Infinite Scroll
	 *
	 * @requires plugins/jquery.infinitescroll.js
	 */
	$('.js-infinitescroll').infinitescroll({
			navSelector: '.js-pagination',
			nextSelector: '.js-pagination a',
			itemSelector: '.js-card',
			loading: {
				finishedMsg: '',
				msgText: ''
			}
		}
	);


	/**
	 * Carousel Sync
	 *
	 * @requires plugins/responsive-carousel.js
	 */
	var arrowleft = '<button type="button" class="slick-prev"><svg title="Prev"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--chevron-left"></use></svg></button>',
		arrowright = '<button type="button" class="slick-next"><svg title="Next"><use xlink:href="' + templateUrl + '/images/svg/sprite.svg#icons--chevron-right"></use></svg></button>';

	$('.js-re-sync-slide').slick({
		autoplay: true,
		autoplaySpeed: 6000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: true,
		fade: true,
		prevArrow: arrowleft,
		nextArrow: arrowright,
		asNavFor: '.js-re-sync-nav'
	});

	$('.js-re-sync-nav').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		asNavFor: '.js-re-sync-slide',
		dots: false,
		arrows: false,
		centerMode: true,
		focusOnSelect: true,
		mobileFirst: true,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 5
				}
			},
			{
				breakpoint: 1280,
				settings: {
					slidesToShow: 7
				}
			}
		]
	});


	/**
	 * Viewmode
	 *
	 * @requires plugins/nouislider.js
	 */
	var viewModeButtons = $('.js-viewmode span'),
		reList = $('.re-list');

	viewModeButtons.on("click", function () {
		var current = $(this);

		viewModeButtons.removeClass('viewmode__option--active');
		current.addClass('viewmode__option--active');

		if (current.hasClass('viewmode__option--list')) {
			reList.removeClass('re-list--gridview').addClass('re-list--listview');
			window.cookieManager.create('viewmode', 'list', 365);
		} else if (current.hasClass('viewmode__option--grid')) {
			reList.removeClass('re-list--listview').addClass('re-list--gridview');
			window.cookieManager.create('viewmode', 'grid', 365);
		}
	});

}(jQuery));