/**
 * Home
 *
 * 01. Anchor Link
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Anchor Link
     *
     * @summary Scroll anchor
     */
    $('.js-hero .tabs').responsiveTabs({
        startCollapsed: '',
        active: 0,
        activate: function(event, tab){
            $('.hero__image').removeClass('is-visible');
            $(tab.tab.data('slave')).addClass('is-visible');
        }
    });

}(jQuery));