/**
 * Sidebar
 *
 * 01. Sticky
 *
 * @global jQuery
 * @author Matteo Casonato
 */

(function ($) {
    'use strict';

    /**
     * Sticky
     *
     * @requires plugins/jquery.sticky-kit.js
     */
    var sticky = $('.js-sticky'),
        mode;

    function mediaquery() {
        if (Modernizr.mq('(min-width: 1024px)')) {
            if (mode !== 'desktop') {
                //console.log('> 1024px');
                mode = 'desktop';

                sticky.stick_in_parent({
                    parent: '.js-sticky-content',
                    offset_top: 60
                });
            }
        } else {
            if (mode !== 'mobile') {
                //console.log('< 1024px');
                mode = 'mobile';
                sticky.trigger("sticky_kit:detach");
            }
        }
    }

    // Call on every window resize
    $(window).smartresize(mediaquery);

    // Call once on initial load
    mediaquery();

    $('.f-filters__label--advanced').on('click', function () {
        setTimeout(function () {
            sticky.trigger("sticky_kit:recalc");
        }, 0);
    });

}(jQuery));
