/**
 * Header
 *
 * 01. Navigation
 *
 * @global jQuery
 * @author Matteo Casonato
 */

/**
 * Navigation
 *
 * @summary Priority Navigation
 * @link https://github.com/gijsroge/priority-navigation
 */
(function ($) {
    'use strict';

    var nav = priorityNav.init({
        initClass:                  "js-nav", // Class that will be printed on html element to allow conditional css styling.
        mainNavWrapper:             ".nav__item--more", // mainnav wrapper selector (must be direct parent from mainNav)
        mainNav:                    ".nav__menu", // mainnav selector. (must be inline-block)
        navDropdownClassName:       "nav__dropdown", // class used for the dropdown - this is a class name, not a selector.
        navDropdownToggleClassName: "nav__dropdown-toggle", // class used for the dropdown toggle - this is a class name, not a selector.
        navDropdownLabel:           "Altro", // Text that is used for the dropdown toggle.
        navDropdownBreakpointLabel: "Menu", //button label for navDropdownToggle when the breakPoint is reached.
        breakPoint:                 500, // amount of pixels when all menu items should be moved to dropdown to simulate a mobile menu
        throttleDelay:              50, // this will throttle the calculating logic on resize because i'm a responsible dev.
        offsetPixels:               0, // increase to decrease the time it takes to move an item.
        count:                      true // prints the amount of items are moved to the attribute data-count to style with css counter.
    });

}(jQuery));